import { Link } from "gatsby";
import React from "react";
import { IIntrestLinksProps } from "./IntrestLinksProps";
import Title3 from "../../global/Title3/Title3";
import { Col, Container, Row } from "react-bootstrap";
import { List } from "./IntrestLinkHelpers";
import { intrestsDiv, links } from "./IntrestLinks.module.scss";

const InterestLinks: React.FC<IIntrestLinksProps> = (props) => {
  return (
    <Container className={`mt-4 mx-lg-auto ${intrestsDiv}`} fluid="sm">
      <Row>
        <Col>
          <Title3> {props.cityName} Hotels by Amenity </Title3>
          <List>
            {props.InterestLinks?.map((interest) => (
              <div className={links} key={interest.title}>
                <h6>
                  <Link
                    id={`cityintrestlink-${interest.title}`}
                    to={interest.url}
                  >
                    {interest.title}
                  </Link>
                </h6>
              </div>
            ))}
          </List>
        </Col>
      </Row>
    </Container>
  );
};

export default InterestLinks;
