import * as React from "react";
import { list } from "./IntrestLinks.module.scss";
import { Container } from "react-bootstrap";

export const List: React.FC<any> = (props) => {
  return (
    <Container {...props} className={list}>
      {props.children}
    </Container>
  );
};
